import { lazy } from 'react';
import { ProtectedLayout } from '../layout/ProtectedLayout';

const PAFContainer = lazy(() => import('features/paf/PAFContainer'));
const CreateSchedule = lazy(() => import('features/manage-booking/create_schedule/index'));
const DoctorLanding = lazy(() => import('features/manage-booking/home/specialist/index'));
const ClinicSchedule = lazy(() => import('features/manage-booking/clinic_schedule/index'));
const HSPClinicSchedule = lazy(() => import('features/manage-booking/hsp-clinic-schedule'));
const PAFCaseDetails = lazy(() => import('features/paf/PAFCaseDetails'));
const PAFEscalation = lazy(() => import('features/paf/PAFEscalation'));
const PAFReviewActions = lazy(() => import('features/paf/PAFReviewActions'));
const LOGWorkList = lazy(() => import('features/log/worklist/LOGWorkList'));
const LOGPatientForm = lazy(() => import('features/log/form/LOGPatientForm'));
const LOGCaseDetail = lazy(() => import('features/log/case-detail/LogCaseDetail'));
const DecisionSupportEngine = lazy(() => import('features/dse/DecisionSupport'));
const PaymentAdvice = lazy(() =>
  import('features/inpatient-transactions-tracker/payment-advice/PaymentAdvice'),
);
// const InpatientTransactionTracker = lazy(() =>
//   import('features/inpatient-transactions-tracker/landing-page/InpatientTransactionTracker'),
// );
const LCForm = lazy(() =>
  import('features/inpatient-transactions-tracker/letter-of-certificate/LCFormV2'),
);
const CMForm = lazy(() => import('features/inpatient-transactions-tracker/collection-memo/CMForm'));
// const LCCaseDetails = lazy(() =>
//   import('features/inpatient-transactions-tracker/case-details/CaseDetails'),
// );

export const providerRoutes = [
  {
    element: ProtectedLayout,
    children: [
      {
        path: '/provider/:providerId',
        element: DoctorLanding,
      },
      {
        path: '/doctor/schedule/create/:unitId/:requesterId/:serviceType',
        element: CreateSchedule,
      },
      {
        path: '/schedule/clinic/provider/:providerId',
        element: ClinicSchedule,
      },
      {
        path: '/schedule/clinic/healthscreening/provider/:providerId',
        element: HSPClinicSchedule,
      },
      {
        path: '/paf',
        element: PAFContainer,
      },
      {
        path: '/paf/draft',
        element: PAFContainer,
        props: { isDraft: true },
      },
      {
        path: '/epaf/case/:caseId',
        element: PAFCaseDetails,
      },
      {
        path: '/epaf/case/:caseId/escalation',
        element: PAFEscalation,
      },
      {
        path: '/epaf/case/:caseId/:action',
        element: PAFReviewActions,
      },
      {
        path: '/letter-of-guarantee',
        element: LOGWorkList,
      },
      {
        path: '/letter-of-guarantee/:caseId/:action',
        element: LOGPatientForm,
      },
      {
        path: '/letter-of-guarantee/:caseId',
        element: LOGCaseDetail,
      },
      {
        path: '/decision-support',
        element: DecisionSupportEngine,
      },
      // {
      //   path: '/inpatient-transactions-tracker',
      //   element: InpatientTransactionTracker,
      // },
      {
        path: '/inpatient-transactions-tracker/payment-advice',
        element: PaymentAdvice,
      },
      {
        path: '/inpatient-transactions-tracker/letter-of-certification',
        element: LCForm,
      },
      {
        path: '/inpatient-transactions-tracker/collection-memo',
        element: CMForm,
      },
      // {
      //   path: '/inpatient-transactions-tracker/lc/:lcuuid',
      //   element: LCCaseDetails,
      // },
    ],
  },
];
