export const AppTheme = {
  light: 'light',
};
export const PALETTE_COLORS = {
  main: 'main',
  light: 'light',
  dark: 'dark',
  transparent: 'transparent',
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
};
