import { useEffect, useState } from 'react';
import { providerRoutes, patientRoutes, commonRoutes } from 'routes';
import { Route, Routes, Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Should modify again when we update react-router to v6
export function Router() {
  const [routes, setRoutes] = useState([]);

  // buildRoutes will be improved again when we update to v6
  function buildRoutes() {
    setRoutes([...providerRoutes, ...patientRoutes, ...commonRoutes]);
  }

  useEffect(() => {
    buildRoutes();
  }, []);

  const renderNestedRoutes = (currentRoutes = []) => {
    return (
      !isEmpty(currentRoutes) &&
      currentRoutes.map(
        ({ path, element: NestedComponent, nestedRoutes, props = {}, redirect }) => {
          return (
            <Route
              key={path}
              path={path}
              element={!redirect ? <NestedComponent {...props} /> : <Navigate to={redirect} />}
            >
              {!isEmpty(nestedRoutes) && renderNestedRoutes(nestedRoutes)}
            </Route>
          );
        },
      )
    );
  };

  const renderRoutes = (currentRoutes = []) => {
    return currentRoutes?.map(({ element: Layout, children }) =>
      children?.map(({ path, element: Component, props = {}, nestedRoutes }) => {
        return (
          <Route
            key={path}
            path={path}
            element={
              <Layout>
                <Component {...props} />
              </Layout>
            }
          >
            {renderNestedRoutes(nestedRoutes)}
          </Route>
        );
      }),
    );
  };

  return (
    !!routes.length && (
      <Routes>
        {renderRoutes(routes)}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    )
  );
}

const PageNotFound = () => {
  window.location.href = '/uapp/page-not-found';
  return <></>;
};
