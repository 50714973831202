export const DISCOVERY_PREFIX = 'discovery/api/v1';
export const BENTO_PREFIX = 'api/v1';
export const ARROW_PREFIX = 'arrow/api';
export const ARROW_PREFIX_V2 = 'arrow/api/v2';
export const PDS_PREFIX = 'pds/v1';
export const PREAUTH_PREFIX = 'preauth/api';
export const PDS_PREFIX_V2 = 'pds/v2';
export const USER_PREFIX = 'users/v1/user';
export const USER_PREFIX_V2 = 'users/v2/user';
export const AUTH_PREFIX_V2 = 'auth/v2/auth';
export const TREE_PREFIX = 'tree/v1';
export const PDS_PREFIX_V3 = 'pds/v3';
export const SMART_PANEL_ADMIN_PREFIX = 'smart-panel/admin/api';
export const PS_PREFIX = 'ps/v1';
