import { lazy } from 'react';
import { ProtectedLayout } from '../layout/ProtectedLayout';

const BookScreening = lazy(() => import('features/book-health-screening'));
const PatientLanding = lazy(() => import('features/manage-booking/home/patient/index'));
const SearchDoctorContainer = lazy(() => import('features/doctor-search/SearchDoctorContainer'));
const OafContainer = lazy(() => import('features/oaf/OafContainer'));
const AppointmentMessage = lazy(() => import('features/create-booking/appointment-message'));
const CreatePatientAppointment = lazy(() =>
  import('features/create-booking/create_patient_appointment/index'),
);
const HealthParamsForm = lazy(() =>
  import('features/book-health-screening/pages/HealthParamsForm'),
);

const PackagesRouter = lazy(() => import('features/book-health-screening/PackageComparisonRouter'));

const PackageListings = lazy(() => import('features/book-health-screening/pages/PackageListings'));

const Comparison = lazy(() => import('features/book-health-screening/pages/PackageComparison'));

const Book = lazy(() => import('features/book-health-screening/pages/Book'));

const SelectSessionWrapper = lazy(() =>
  import('features/create-booking/create_patient_appointment/SelectSessionWrapper'),
);

export const patientRoutes = [
  {
    element: ProtectedLayout,
    children: [
      {
        path: '/patient',
        element: PatientLanding,
      },
      {
        path: '/health-screening',
        element: BookScreening,
        exact: false,
        redirect: 'form',
        nestedRoutes: [
          {
            path: 'form',
            element: HealthParamsForm,
          },
          {
            path: 'packages',
            element: PackagesRouter,
            nestedRoutes: [
              {
                path: 'listings',
                element: PackageListings,
              },
              {
                path: 'comparison',
                element: Comparison,
              },
              {
                path: '*',
                redirect: 'listings',
              },
              {
                path: 'packages',
                redirect: 'listings',
              },
            ],
          },
          {
            path: 'book',
            element: Book,
          },
          {
            path: 'select-session',
            element: SelectSessionWrapper,
          },
          {
            path: '/health-screening',
            redirect: 'form',
          },
          {
            path: '*',
            redirect: 'form',
          },
        ],
      },
      {
        path: '/search-doctor',
        element: SearchDoctorContainer,
      },
      {
        path: '/opd/patient/client/form/:requesterId',
        element: OafContainer,
      },
      {
        path: '/appointment/:serviceType/:requesterId/complete',
        element: AppointmentMessage,
      },
      {
        path: '/schedule/create/:unitId/:requesterId/:serviceType',
        element: CreatePatientAppointment,
      },
    ],
  },
];
