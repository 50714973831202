import HttpServices from './http_services';
import { SMART_PANEL_ADMIN_PREFIX, API_VERSION } from './constants';

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  DOCTOR: 'Specialist',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
};

export const GET_ROLE_USER_URL = `${SMART_PANEL_ADMIN_PREFIX}/${API_VERSION}/users/userInfo`;

const getRoleUser = () => HttpServices.get(GET_ROLE_USER_URL);

const RoleService = {
  getRoleUser,
};
export default RoleService;
