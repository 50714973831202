import { Alert, MasterLayout } from '@smarterhealth/ui-components';
import { useEffect, useState } from 'react';
import { getMetaData, getUserPayors, getTokenByPayor, getRoleUserDoctor } from 'apis/apis';
import { Constants, MultiPayor } from '@smarterhealth/utilities';

export const WrapperLayout = ({ children, isHideSwitchPayor, ...props }) => {
  const [payors, setPayors] = useState([]);
  const [confirmSelectedPayor, setConfirmSelectedPayor] = useState(null);

  const { error, switchPayorAction } = MultiPayor.useSwitchPayor({
    getMetadata: getMetaData,
    getTokenByPayor,
    getRoleUserDoctor: getRoleUserDoctor,
    onSuccessSwitchPayor: handleAfterSwitchPayor,
  });

  const newHeaderProps = {
    ...props.headerProps,
    payors: !isHideSwitchPayor && payors,
    selectedPayor: confirmSelectedPayor,
    handleChangePayor: handleChangePayor,
  };

  const newProps = { ...props, headerProps: newHeaderProps };

  const newSelection = localStorage.getItem(Constants.SELECTED_PAYOR_KEY);

  function handleAfterSwitchPayor() {
    window.location.href = '/uapp/choose-menu';
  }

  function handleChangePayor(value) {
    switchPayorAction(value);
  }

  useEffect(() => {
    async function fetchUserPayor() {
      try {
        const res = await getUserPayors();
        if (res?.data) {
          let temp = [];
          res.data.forEach((item) => {
            temp.push({
              ...item,
              payorName: item.displayName,
              id: item.payorId,
            });

            if (item.selected) {
              setConfirmSelectedPayor(newSelection || item.payorId);
            }
          });
          localStorage.setItem(Constants.USER_PAYORS, JSON.stringify(temp));
          setPayors(temp);
        }
      } catch (errors) {
        setPayors([]);
        setConfirmSelectedPayor(null);
      }
    }

    const cacheUserPayor = localStorage.getItem(Constants.USER_PAYORS);
    if (cacheUserPayor && JSON.parse(cacheUserPayor)) {
      setPayors(JSON.parse(cacheUserPayor));
    } else if (!isHideSwitchPayor) {
      fetchUserPayor();
    }
  }, [isHideSwitchPayor, newSelection]);

  useEffect(() => {
    if (newSelection) {
      setConfirmSelectedPayor(newSelection);
    }
  }, [newSelection]);

  return (
    <MasterLayout {...newProps}>
      {error && (
        <Alert severity="error" style={{ marginBottom: '10px' }}>
          {error}
        </Alert>
      )}
      <>{children}</>
    </MasterLayout>
  );
};
