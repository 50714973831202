import HttpServices from './http_services';
import RoleService from './role.service';
import { userAuthStorage } from './utils';

const AUTH_VERSION = 'v2';

async function login(email, password) {
  const params = new URLSearchParams();
  params.append('username', email);
  params.append('password', password);

  const loginRes = await HttpServices.publicPost(`/auth/${AUTH_VERSION}/auth/token/jwt`, params, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  if (loginRes.data.access_token) userAuthStorage.setUserAuth(JSON.stringify(loginRes.data));

  const roleUserRes = await RoleService.getRoleUser();
  const roles = roleUserRes?.data?.content?.roles || [];
  const doctorId = roleUserRes?.data?.content?.doctorId;
  const userAuth = userAuthStorage.getUserAuth();
  const nextUserAuth = { ...userAuth, roles, id: doctorId };
  userAuthStorage.setUserAuth(JSON.stringify(nextUserAuth));

  return nextUserAuth;
}

function logout() {
  userAuthStorage.removeUserAuth();
}

async function revalidateAccessToken(refreshToken) {
  // When getting fresh access token, we need the refreshToken as the bearer for
  // `Authorization` header.
  const res = await HttpServices.publicGet(`/auth/${AUTH_VERSION}/auth/token/jwt/refresh`, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  const userAuth = userAuthStorage.getUserAuth();

  const nextUserAuth = {
    // Preserve roles and id.
    ...userAuth,
    // Change the existing "access" and "refresh" token.
    ...res.data,
  };

  // Update userAuth in the storage.
  userAuthStorage.setUserAuth(JSON.stringify(nextUserAuth));

  return nextUserAuth;
}

const AuthService = {
  login,
  logout,
  revalidateAccessToken,
};
export default AuthService;
