import { AppTheme, PALETTE_COLORS } from './theme_type';
import { grey } from '@material-ui/core/colors';

const main_color = '#03ACEF';
const secondary_mainBackground = '#ffffff';
const main_navColor = '#03ACEF';
const main_titleBarColor = '#FFFFFF';

const appThemeOptions = {
  [AppTheme.light]: {
    spacing: 10,
    palette: {
      primary: {
        light: secondary_mainBackground,
        main: main_color,
        dark: '#D3D3D3',
      },
      secondary: {
        light: '#EEF1F4',
        main: secondary_mainBackground,
        dark: grey[500],
      },
      primaryBlue: '#03ACEF',
      shades: {
        [PALETTE_COLORS.transparent]: 'rgba(0,0,0,0.5)',
        [PALETTE_COLORS.main]: '#7F7F7F',
        [PALETTE_COLORS.light]: '#F2F2F2',
        [PALETTE_COLORS.dark]: '#262626',
        [PALETTE_COLORS['100']]: '#FFFFFF',
        [PALETTE_COLORS['200']]: '#FAFAFA',
        [PALETTE_COLORS['300']]: '#F5F4F8',
        [PALETTE_COLORS['400']]: '#000000',
      },
      neutral: {
        [PALETTE_COLORS.main]: '#7F7F7F',
        [PALETTE_COLORS.light]: '#F2F2F2',
        [PALETTE_COLORS.dark]: '#262626',
        [PALETTE_COLORS['100']]: '#F2F2F2',
        [PALETTE_COLORS['200']]: '#D9D9D9',
        [PALETTE_COLORS['300']]: '#BFBFBF',
        [PALETTE_COLORS['400']]: '#A6A6A6',
        [PALETTE_COLORS['500']]: '#7F7F7F',
        [PALETTE_COLORS['600']]: '#595959',
        [PALETTE_COLORS['700']]: '#404040',
        [PALETTE_COLORS['800']]: '#262626',
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          backgroundColor: main_titleBarColor,
        },
      },
      MuiBottomNavigation: {
        root: {
          backgroundColor: main_color,
        },
      },
      MuiBottomNavigationAction: {
        root: {
          color: 'rgb(255,255,255)',
        },
      },
      MuiBadge: {
        badge: {
          backgroundColor: '#F37D2A',
          color: 'white',
        },
      },
      MuiTabs: {
        root: {
          color: main_navColor,
          '&$selected': {
            backgroundColor: '#03ACEF',
          },
        },
      },
    },
    breakpoints: {
      keys: ['xs', 'mobile', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        mobile: 360,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
};

export default appThemeOptions;
