import { createContext, useMemo } from 'react';
import { Metadata } from '@smarterhealth/utilities';

export const MetadataContext = createContext({});

export const MetadataProvider = ({ key = '', ...props }) => {
  // TO DO: Check if isMicroAPP => get authToken
  const { metadata, getDataFrom, isFetched, ...rest } = Metadata.useMetadata({ key });
  const value = useMemo(() => ({ metadata, getDataFrom, isFetched, ...rest }), [isFetched]);
  return <MetadataContext.Provider value={value}>{props.children}</MetadataContext.Provider>;
};
