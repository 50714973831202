import { useEffect, useState, Suspense } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Metadata } from '@smarterhealth/utilities';
import { getMenu, postLogout } from '@apis';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { httpResponseEventEmitter, HttpResponseEvents } from 'services/http_services/utils';
import { checkIsUserLoggedIn } from 'utils/AuthService';
import { WrapperLayout } from './components/WrapperLayout';

const STATIC = 'static';

export function ProtectedLayout({ children }) {
  const [args, setArgs] = useState({});
  const classes = useStyles({ static: args?.sideBarProps?.open });
  const location = useLocation();
  const navigate = useNavigate();

  // Note: when cookies are fully removed, line 18 can be replaced to retrieve from MetadataContext
  const metadata = Metadata.getDataFromLocalStorage() || null;
  const user = checkIsUserLoggedIn();
  const clientId = metadata?.clientId || '';
  const roleNames = metadata?.roleNames || [];
  const userId = metadata?.userId || '';

  const renderLink = ({ url, children, className, activeClassName }) => {
    return (
      <NavLink
        style={{ fontSize: '16px', textDecoration: 'none' }}
        onClick={() => (window.location.href = url)}
        className={() => {
          const _classNames = [className];
          const pathname = window.location.pathname;
          if (pathname !== url) {
            return clsx(..._classNames);
          }
          _classNames.push(activeClassName);
          return clsx(..._classNames);
        }}
        to="#"
      >
        {children}
      </NavLink>
    );
  };

  function handleRedirection() {
    const previousURL = 'previousUrl';
    if (!metadata || !user || !roleNames) {
      if (!location.pathname.includes('login')) {
        localStorage.setItem(previousURL, window.location.href);
      } else {
        localStorage.removeItem(previousURL);
      }
      window.location.href = '/uapp/login';
    }
  }

  const logout = async (clientId = '') => {
    await postLogout();
    localStorage?.clear();
    if (clientId) {
      window.location.href = `/uapp/${clientId}/login`;
    } else {
      window.location.href = '/uapp/login';
    }
  };

  function reOrder(arr) {
    // function to re-order the menu based on ordinal, and to replace %d with userId for specialist
    for (const element of arr) {
      let url = element.url;
      if (!element?.subMenu?.length && element.label !== 'Log out' && url?.includes('%d')) {
        element.url = element.url.replace('%d', userId);
      }
      if (element.subMenu) {
        for (const subElement of element.subMenu) {
          let url = subElement.url;
          if (url.includes('%d')) {
            subElement.url = url.replace('%d', userId);
          }
        }
        element.menus = element.subMenu;
        delete element.subMenu;
      }
      if (element.label === 'Log out') {
        element.url = () => logout(clientId);
      }
    }
    return arr;
  }

  function removeDuplicatedSubMenu(arr) {
    let result = {};
    for (const element of arr) {
      if (!result[element.label]) {
        result[element.label] = element;
      }
    }
    return Object.values(result);
  }

  function removeDuplicatedMenu(arr) {
    let obj = {};
    for (const element of arr) {
      if (!obj[element.label]) {
        obj[element.label] = element;
      } else {
        let mergedMenus = [...obj[element.label].subMenu, ...element.subMenu];
        let uniq = removeDuplicatedSubMenu(mergedMenus);
        obj[element.label].subMenu = uniq;
      }
    }
    return Object.values(obj);
  }

  async function fetchMenu() {
    let sidebarMenu = [];
    let headerMenu = [];

    const apiPromises = roleNames?.map((roleName) => getMenu(clientId, roleName));

    await Promise.all(apiPromises)
      .then((values) => {
        for (const element of values) {
          let menuItem = element.data?.data?.menus?.sort((a, b) => a.ordinal - b.ordinal);
          for (const element of menuItem) {
            if (element.displayPosition === 'left') {
              sidebarMenu.push(element);
            } else if (element.displayPosition === 'right') {
              headerMenu.push(...element.subMenu);
            }
          }
        }
      })
      .catch((err) => console.log(err));

    headerMenu = removeDuplicatedMenu(headerMenu);
    sidebarMenu = removeDuplicatedMenu(sidebarMenu);

    return {
      reOrderSidebar: reOrder(sidebarMenu),
      reOrderHeader: reOrder(headerMenu),
    };
  }

  useEffect(() => {
    handleRedirection();
    fetchMenu()
      .then((res) => {
        let props = {};
        if (res?.reOrderSidebar?.[0]?.layout === STATIC) {
          props = {
            open: true,
            classes: { backdrop: classes.backdrop, container: classes.sideBarContainer },
          };
        }
        let filterMenu = [];
        res?.reOrderSidebar?.map((res) => {
          if (!res.menus?.length) {
            filterMenu.push(res);
          } else {
            filterMenu.push(...res.menus);
          }
        });

        setArgs({
          sideBarProps: {
            menus: filterMenu,
            renderLink: renderLink,
            ...props,
          },
          headerProps: {
            myAccountMenu: res.reOrderHeader,
            logo: localStorage.getItem('logo') ? JSON.parse(localStorage.getItem('logo')) : '',
            onLogoClick: () => (window.location.href = '/uapp/choose-menu'),
            isHideBurgerIcon: res?.reOrderSidebar?.[0]?.layout === STATIC,
          },
        });
      })
      .catch((err) => console.log(err));
  }, [userId]);

  useEffect(() => {
    function handleErrorResponse() {
      navigate('/no-permission');
    }
    httpResponseEventEmitter.on(HttpResponseEvents.permissionDenied, handleErrorResponse);
  }, []);

  const computeMasterLayoutStyle = () => {
    if (location.pathname.includes('/no-permission')) {
      return { container: classes.container };
    }
    return {};
  };

  return (
    <WrapperLayout {...args} classes={computeMasterLayoutStyle()}>
      <div style={{ margin: args?.sideBarProps?.open && '0px -55px 0px 185px' }}>
        <Suspense fallback={<p>Loading...</p>}>{children}</Suspense>
      </div>
    </WrapperLayout>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#ffffff',
  },
  backdrop: (props) => ({
    backgroundColor: props.static && 'unset',
    zIndex: 'unset',
    inset: 'unset',
  }),
  sideBarContainer: (props) => ({
    padding: props.static && '10px',
    width: props.static && '240px',
  }),
}));
