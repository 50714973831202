import axios from 'axios';
import dotenv from 'dotenv';
import { BASE_URL } from './constants';
import { authValue } from 'features/common/config';
import { Sentry } from '@smarterhealth/utilities';
import { userAuthStorage, httpResponseEventEmitter, HttpResponseEvents } from './utils';
import AuthService from './auth.service';

dotenv.config();

const commonHeader = (config = {}) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      ...config,
    },
  };
};

export const getAuthorizeHeader = (configHeader = {}) => {
  try {
    const userAuth = userAuthStorage.getUserAuth();

    const token = userAuth.access_token;
    const authorizeHeader = { Authorization: `Bearer ${token}` };
    const config = {
      headers: {
        ...authorizeHeader,
        ...commonHeader(configHeader).headers,
      },
    };
    // `responseType` is not a "HTTP Header". Rather, it is an axios config "key".
    // Check if `configHeader.responseType` is defined. If yes, then pass it
    // as the root level key of the axios config, instead of adding it to HTTP Header.
    if (configHeader.responseType) {
      config.responseType = configHeader.responseType;
    }
    return config;
  } catch (err) {
    Sentry.captureException(err);
    // TODO: Handle App error handling.
  }
};

/**
 * Axios instance for all request
 * **/
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  xsrfCookieName: process.env.REACT_APP_ARROW_XSRFCOOKIENAME ?? 'XSRF-TOKEN',
  xsrfHeaderName: process.env.REACT_APP_ARROW_XSRFHEADERNAME ?? 'X-XSRF-TOKEN',
  timeout: process.env.REACT_APP_AXIOS_REQUEST_TIMEOUT ?? 30000,
});

/**
 * Request Interceptor
 * ** Modify headers/Authentication token and any requests configuration
 * */
/**
 * Request Interceptor
 * ** Modify headers/Authentication token and any requests configuration
 * */
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Sentry.captureException(error);
    /* istanbul ignore next */
    return Promise.reject(error);
  },
);

// Track the refresh token request to avoid multiple request for the same endpoint at the same time, and can be used to avoid
// infinite loop if the auth server returns 401 for the refresh token request.
let IS_REFRESH_TOKEN_REQUEST_PENDING = false;

/**
 * Response Interceptor
 * ** Modify respond format, error handling, status handling
 * */
axiosInstance.interceptors.response.use(
  (response) => {
    return {
      code: response.data,
      data: response.data,
    };
  },
  async (error) => {
    const status = error?.response?.status;

    // NOTE:
    // - 401 equals to "Unauthorized status". It means client provides no credentials or invalid credentials. E.g not logged user or access token is already expiredd.
    // - 403 equals to "Forbidden status". It means client has valid credentials but not enough privileges to perform an action on a resource. E.g no right access or
    //  there is logged user but the "refresh token" is already expired. Due to this
    //  it can't request new access token.
    switch (status) {
      case 401: {
        const metaData = localStorage.getItem('metadata');
        const user = localStorage.getItem('user');
        const clientId = window.sessionStorage.getItem('client');
        const clientIdLogin = clientId ? `${clientId}/` : '';

        if (!error.response.request.responseURL.includes('jwt/verify') && !metaData && !user) {
          window.location.href = `/uapp/${clientIdLogin}login`;
        }
        const userAuth = userAuthStorage.getUserAuth();
        const originalRequest = error.config;

        if (IS_REFRESH_TOKEN_REQUEST_PENDING) {
          return Promise.reject(error);
        }

        // We will check first if there is logged user via checking the Local storage.
        // If there is logged user, then check if the "refresh token" can request
        // fresh "access token."
        if (userAuth && !!userAuth.refresh_token && !originalRequest._retry) {
          try {
            // Track the "retry request". Useful for avoiding infinite retry requests loop.
            // Idea is we only want to "retry" the request once.
            originalRequest._retry = true;
            // Set to "true" to avoid multiple request for refreshing token.
            IS_REFRESH_TOKEN_REQUEST_PENDING = true;

            const nextUserAuth = await AuthService.revalidateAccessToken(userAuth.refresh_token);

            // Set to "false" to allow new request.
            IS_REFRESH_TOKEN_REQUEST_PENDING = false;

            // Update the `Authorization` header using the new `access_token`
            // but use the existing axios config.

            originalRequest.headers.Authorization = `Bearer ${nextUserAuth.access_token}`;

            // Retry the original request with new access token.
            // Returned Promise will be passed to service function that triggers the original request.
            return axiosInstance(originalRequest);
          } catch (err) {
            // Set to "false" to allow new request.
            IS_REFRESH_TOKEN_REQUEST_PENDING = false;
            httpResponseEventEmitter.emit(HttpResponseEvents.sessionTimeout, {
              message: `You don't have permission to access this document.`,
              title: 'Forbidden',
              status,
            });
          }
        }
        // If no logged user or data doesn't pass some criterias, then notify `httpResponseEventEmitter`.
        else {
          httpResponseEventEmitter.emit(HttpResponseEvents.permissionDenied, {
            message:
              'This server could not verify that you are authorized to access the document requested.',
            title: 'Authorization Required',
            status,
          });
        }
        break;
      }
      case 403: {
        httpResponseEventEmitter.emit(HttpResponseEvents.permissionDenied);
        break;
      }
      default: {
        break;
      }
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  },
);

/**
 * Get Request
 * **/
export const get = (url, config) => {
  return axiosInstance.get(url, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    headers: {
      Authorization: authValue,
      ...config?.headers,
    },
  });
};

/**
 * Post Request
 * **/
export const post = (url, data, config = {}) => {
  return axiosInstance.post(url, data, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authValue,
      ...config?.headers,
    },
  });
};

/**
 * Patch Request
 * **/
export const patch = (url, data) => {
  return axiosInstance.patch(url, data);
};

/**
 * Put Request
 * **/
export const put = (url, data) => {
  return axiosInstance.put(url, data);
};

/**
 * Delete Request
 * **/
export const del = (url) => {
  return axiosInstance.delete(url);
};

/**
 * Get Request
 * **/
export const publicGet = (url, config = {}) => {
  return axiosInstance.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...config,
  });
};

/**
 * Post Request
 * **/
export const publicPost = (url, data, configHeader = {}) => {
  return axiosInstance.post(url, data, commonHeader(configHeader));
};

/**
 * Put Request
 * **/
export const publicPut = (url, data) => {
  return axiosInstance.put(url, data, commonHeader());
};

/**
 * Patch Request
 * **/
export const publicPatch = (url, data) => {
  return axiosInstance.patch(url, data, commonHeader());
};

/**
 * Delete Request
 * **/
export const publicDel = (url) => {
  return axiosInstance.delete(url);
};

/**
 * Post Request
 * **/
export const postForPDSMigration = (url, data, config = {}) => {
  return axiosInstance.post(url, data, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      'X-OPD-V2': 'true',
      ...getAuthorizeHeader(config?.headers)?.headers,
    },
  });
};

export const getForPDSMigration = (url) => {
  return axiosInstance.get(url, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    headers: {
      Authorization: authValue,
      'X-OPD-V2': 'true',
    },
  });
};

export const putForPDSMigration = (url, data, config = {}) => {
  return axiosInstance.put(url, data, {
    timeout: process.env.REACT_AXIOS_REQUEST_TIMEOUT ?? 30000,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      'X-OPD-V2': 'true',
      Authorization: authValue,
    },
  });
};

const HttpServices = {
  get,
  post,
  put,
  del,
  patch,
  publicGet,
  publicPost,
  publicPut,
  publicPatch,
  publicDel,
  postForPDSMigration,
  getForPDSMigration,
  axiosInstance,
};
export default HttpServices;
