import { lazy } from 'react';
import { ProtectedLayout } from '../layout/ProtectedLayout';

const CaseDetails = lazy(() => import('features/manage-booking/home/case-details'));
const NoPermissionPage = lazy(() => import('features/layout/no-permission/NoPermission'));

export const commonRoutes = [
  {
    element: ProtectedLayout,
    children: [
      {
        path: '/view/:serviceType/:requesterId',
        element: CaseDetails,
      },
      {
        path: '/no-permission',
        element: NoPermissionPage,
      },
    ],
  },
];
