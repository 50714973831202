import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core';
import { Sentry } from '@smarterhealth/utilities';
import { SENTRY_DSN } from 'features/common/config';
import appThemeOptions from 'features/app_theme/theme';
import { Router } from 'routes/Router';
import { MetadataProvider } from 'providers/MetadataProvider';

/* istanbul ignore next */
Sentry.init(process.env.REACT_APP_SENTRY_DSN || SENTRY_DSN);

const App = () => {
  const appTheme = createTheme(appThemeOptions.light);
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <MuiThemeProvider theme={appTheme}>
            <MetadataProvider>
              <Router />
            </MetadataProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </HelmetProvider>
  );
};

Sentry.withProfiler(App);
export default App;
