import Cookies from 'universal-cookie';
import {
  get,
  post,
  postForPDSMigration,
  put,
  getForPDSMigration,
  putForPDSMigration,
} from 'services/http_services/http_services';
import {
  ARROW_PREFIX,
  BENTO_PREFIX,
  DISCOVERY_PREFIX,
  USER_PREFIX_V2,
  AUTH_PREFIX_V2,
  PDS_PREFIX,
  TREE_PREFIX,
  SMART_PANEL_ADMIN_PREFIX,
} from './utils/constant';
import { userIdKey, clientKey, roleTypeKey, authValue } from 'features/common/config';
import { Metadata } from '@smarterhealth/utilities';

const cookies = new Cookies();

const metadata = Metadata.getDataFromLocalStorage();
// To remove cookies in the future
const roleNames = metadata?.roleNames || [];
const userId = metadata?.userId ?? cookies.get(userIdKey);
export const client = metadata?.clientId ?? cookies.get(clientKey);
export let roleId = cookies.get(roleTypeKey);
if (roleNames) {
  for (const element of roleNames) {
    if (element !== 'ROLE_ADMIN' && element !== 'Specialist' && element !== 'ROLE_SUPER_ADMIN') {
      roleId = element;
    }
  }
}

export const getConsolidatedCountries = () =>
  get(`${DISCOVERY_PREFIX}/country/consolidated/client/${client}`);

export const getDoctor = (specialistId) =>
  post(`${ARROW_PREFIX}/specialist/${specialistId}/details?clientName=${client}`, {});

export const getDoctors = (payload) =>
  post(`${DISCOVERY_PREFIX}/doctor`, { ...payload, clientName: client });

export const postBookingInitiation = (payload) =>
  postForPDSMigration(`${PDS_PREFIX}/opd/appointment?patientId=${userId}`, payload, {
    headers: { Authorization: authValue },
  });

export const getOPDBookingCaseDetail = (payload) =>
  getForPDSMigration(
    `${PDS_PREFIX}/${payload.requestType}/refNo/${payload.refNo}/details?userId=${userId}`,
  );

export const getSessionSchedule = (unitId) => get(`${BENTO_PREFIX}/schedule/unitId/${unitId}`);

export const postPreferredChoice = (unitId, requesterId, payload) =>
  post(`${BENTO_PREFIX}/schedule/book/unitId/${unitId}/requesterId/${requesterId}`, payload, {
    headers: { 'X-OPD-V2': 'true' },
  });

export const getSpecialties = (country) => get(`${DISCOVERY_PREFIX}/specialty/country/${country}`);

export const getHealthParams = () =>
  get(`${ARROW_PREFIX}/mcu/attributes/${userId}/client/${client}`);

export const postQueryForRecommendations = (payload) =>
  post(`${ARROW_PREFIX}/mcu/recommendations/${userId}/client/${client}`, payload);

export const getUserV2 = () => get(`${USER_PREFIX_V2}/profile`);

export const postForPackageLocations = ({ packageId, country, region, city }) =>
  post(`${ARROW_PREFIX}/mcu/package/${packageId}/locations/client/${client}`, {
    country,
    region,
    city,
  });

export const putSelectedLocation = (payload) =>
  put(`${ARROW_PREFIX}/mcu/selectlocation/${userId}/client/${client}`, payload);

//case details related
export const getCaseDetails = (serviceType, requesterId) =>
  get(`${ARROW_PREFIX}/v1/${serviceType}/refNo/${requesterId}/details/${userId}`);

export const getBookingDetails = (unitId, requesterId) => {
  return getForPDSMigration(
    `${BENTO_PREFIX}/booking/unitId/${unitId}/requesterId/${requesterId}/details`,
  );
};

//calendar related
export const getMCUCalendar = () => get(`${BENTO_PREFIX}/mcu/provider/booking`);

export const postProviderClinicSchedule = (payload) =>
  post(`${BENTO_PREFIX}/slot/unitIds`, payload);

export const updateAppointmentStatus = (payload, config) =>
  put(`${BENTO_PREFIX}/booking`, payload, config);

export const updateAppointmentStatusForOPD = (payload) =>
  putForPDSMigration(`${BENTO_PREFIX}/booking`, payload);

export const getOPDCalendarDoctorFirst = (providerId) =>
  get(`${BENTO_PREFIX}/provider/${providerId}/booking/resource`);

export const getOPDCalendarLocationFirst = (providerId) =>
  get(`${BENTO_PREFIX}/provider/${providerId}/booking/location`);

export const getProviderSchedule = (unitId) => get(`${BENTO_PREFIX}/slot/unitId/${unitId}`);

export const postCreateSchedule = (unitId, payload) =>
  post(`${BENTO_PREFIX}/slot/time/unitId/${unitId}`, payload);

export const postCreateScheduleSubmit = (unitId, requesterId, payload) =>
  postForPDSMigration(
    `${BENTO_PREFIX}/slot/book/unitId/${unitId}/requesterId/${requesterId}`,
    payload,
  );

export const getPAFCaseDetails = (caseId) => get(`${PDS_PREFIX}/epaf/summary/caseId/${caseId}`);

export const postHospitalCharges = (payload) => post(`${PDS_PREFIX}/hospital/cost`, payload);

export const postSpecialistSignLater = (caseId) =>
  post(`${PDS_PREFIX}/epaf/draft/sign-later`, { caseId });

export const postAppointmentWorkListForPatient = (payload) =>
  post(`${PDS_PREFIX}/worklist/patient/${userId}`, payload, {
    headers: {
      Authorization: authValue,
    },
  });

export const postAppointmentWorkListByRole = (payload) =>
  postForPDSMigration(`${PDS_PREFIX}/worklist/clients/${roleId}/${userId}`, payload, {
    headers: {
      Authorization: authValue,
    },
  });

export const postAppointmentWorklistFilter = (payload) =>
  postForPDSMigration(`${PDS_PREFIX}/worklist/clients/${roleId}/${userId}/filter`, payload, {
    headers: {
      Authorization: authValue,
    },
  });

export const getIncompleteAppointment = () =>
  get(`${PDS_PREFIX}/opd/appointment/incomplete?patientId=${userId}`);

// V2 Endpoints
export const getMenu = (clientId, roleName) =>
  get(`/${TREE_PREFIX}/payors/${clientId}/roles/attributes/menu?roleName=${roleName}`);
//logout
export const postLogout = (payload) => post(`${AUTH_PREFIX_V2}/logout`, payload);

export const getUserPayors = () => get(`${USER_PREFIX_V2}/payors`);

export const getTokenByPayor = (payload) => post(`${AUTH_PREFIX_V2}/token/jwt/payor`, payload);

export const getMetaData = () => get(`${USER_PREFIX_V2}/metadata`);

export const getRoleUserDoctor = () => get(`${SMART_PANEL_ADMIN_PREFIX}/v1/users/userInfo`);
